import React from "react";
import Header from "../components/header";

import { Helmet } from "react-helmet";
import "../styles/index.css";

function Index() {
  return (
    <main>
      <Helmet>
        <title>RideAmigos Program Menu</title>
      </Helmet>

      <Header />

      <section class="bg-rideamigos">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-2 order-lg-1">
        <h1>RideAmigos Program Menu</h1>
        <small >design / prototyping / user testing</small>

        <p class="lead">
          Design, prototype and testing of new feature to verify carpool trips
        </p>
      </div>
      <div class="col-lg-6 order-1 order-lg-2">
        <img src="/static/strategy-tool-result.png" class="img-fluid" alt=""></img>
      </div>
    </div>
  </div>
</section>

{/* content  */}

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <h4>Problem</h4>
        <p class="">
          The RideAmigos platform offers organizations a way to track and incentivize non-SOV (Single Occupancy Vehicle)
          commuting for their employees. In many cases, carpooling provides significant reductions in traffic and
          vehicle emissions while providing more speed than walking or bicycling and more schedule flexibility than
          public transit or vanpools. For incentivized commuting to work, the employer needs an easy and trustworthy way
          to track and verify carpools, and the carpoolers need an easy and trustworthy way to track and report their
          trips to the employer. This presented significant design challenges from the start

        </p>
        <ul class="">
          <li> Ensure the user and transaction flow could be easily understood by the RideAmigos BizDev, Dev, Customer
            Success and Sales teams </li>
          <li>
            Test the concept interactively before writing any code
          </li>
          <li>
            Present the interactive prototype to internal and external stakeholders for buy-in
          </li>
          <li>
            Design for eventual inclusion in the mobile app
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <h4>The Solution</h4>
        <p class="">
          Because the eventual solution would be complex and require significant changes to both front end and back end
          code, our initial approach relied heavily on flow diagrams and mid-fidelity interactive prototypes. We shared
          and discussed these first artifacts with internal stakeholders from the RideAmigos BizDev, Dev, Customer
          Success and Sales teams and a select group of customers to achieve clarity before writing a line of code.</p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <h4>My Role</h4>
        <p class="">
          My role in this project was to create the prototypes and help ensure shared clarity in the minds of the
          internal and external stakeholders. </p>


      </div>
    </div>
  </div>
</section>

{/* begin research */}
<section class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-2 order-lg-2">
        <h2>Research</h2>


        <p class="">
          In this phase of the project, we used artifact-centered focus groups to engage stakeholders from the
          RideAmigos BizDev, Dev, Customer Success and Sales teams, and
          then with a select group of customers. The primary goals were to discover and iterate on any parts of the
          process and interface design that were unclear, unworkable or might not support the broader goals of the
          project.
        </p>
      </div>
      <div class="col-lg-6 order-2 order-lg-1">
        <a href="#research">
          <figure class="figure">
            <img src="/static/carpool-verification-research.png" class="figure-img img-fluid" alt=""></img>
            <figcaption class="figure-caption text-center">
              A user story-centered design we used to engage stakeholders
            </figcaption>
          </figure>
        </a>
      </div>

    </div>
  </div>
</section>

{/* end research  */}


{/* begin insights */}
<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-2 order-lg-1">
        <h2>Insights</h2>


        <p class="">
          Though the insights at this phase of the project were few and subtle, they had huge implications for the
          future of the project. In reviewing the administrator view of a carpool for instance, serious future privacy
          concerns were surfaced. At the time, anyone who logged a carpool together and the admin who reviewed the
          details would be doing so as part of the same organizational instance of RideAmigos. They had all signed the
          same privacy agreement, and almost always knew each other outside of the platform.

        </p>

        <p class="">In our sessions it became clear that this would present problems in future
          multi-organizational versions of the platform that would allow the details of a commuter from one organization
          (e.g. an employer) to be seen by the admin of another (e.g., a regional transportation agency). </p>
      </div>
      <div class="col-lg-6 order-2 order-lg-1">
        <a href="#insights">
          <figure class="figure">
            <img src="/static/carpool-verification-insights.png" class="figure-img img-fluid" alt=""></img>
            <figcaption class="figure-caption text-center">
              A few quick sketches from discussions with stakeholders
            </figcaption>
          </figure>
        </a>
      </div>
    </div>
  </div>

</section>

 {/* end insights */}

{/* begin prototypes */}
<section class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-2 order-lg-2">
        <h2>Prototypes</h2>


        <p class="">
          In order to focus on high-level process questions, we used flows and high-fidelity clickable prototypes with
          simple interactions and explanatory captions.
        </p>
      </div>

      <div class="col-lg-6 order-2 order-lg-1">
        <a href="#prototypes">

          <figure class="figure">
            <img src="/static/carpool-verification-prototypes.gif" class="figure-img img-fluid" alt=""></img>
            <figcaption class="figure-caption text-center">
              We chose to use a simple clickable prototype to focus discussions
            </figcaption>
          </figure>
        </a>
      </div>

    </div>
  </div>
</section>

{/* end prototypes */}

{/* begin testing  */}

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-1 order-lg-1">
        <h2>Testing and Iteration</h2>


        <p class="">
          Phase 2 of the project would include user testing and iteration to ensure that the process is easy,
          trustworthy and useful for commuters and for admins. In this phase however, iterations were based on
          discussions between internal and customer stakeholders.

        </p>

        <p class="">
          The admin screen, for example, went through several iterations to limit the amount of personal commuter data
          available to the admin.

        </p>

      </div>
      <div class="col-lg-6 order-2 order-lg-2">
        <a href="#testing">

          <figure class="figure">
            <img src="/static/carpool-verification-testing.png" class="figure-img img-fluid" alt=""></img>
            <figcaption class="figure-caption text-center">
              Three iterations of the admin screen
            </figcaption>
          </figure>
        </a>
      </div>
    </div>
  </div>
</section>

{/* <!-- end testing --> */}

{/* <!-- begin conclusions --> */}

<section class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 order-2 order-lg-2">
        <h2>Conclusions</h2>


        <p class="">
          Perhaps the most difficult design challenge in Phase 1 was to fit the fidelity and format of the prototype to
          the needs of the stakeholders. By facilitating discussions using high-level flows and high-fidelity,
          limited-interaction clickable prototypes we were able to relatively quickly surface important big-picture
          issues early in the project.
        </p>
        <p class="">
          As a result, we were able to avoid several future privacy, legal and architectural mis-steps, and to focus on
          the solutions to be designed, tested and rolled out in Phase 2, which is scheduled to begin in early 2020.

        </p>
      </div>

      <div class="col-lg-6 order-2 order-lg-1">
        <a href="#conclusions">

          <figure class="figure">
            <img src="" class="figure-img img-fluid" alt=""></img>
            <figcaption class="figure-caption text-center">

            </figcaption>
          </figure>
        </a>
      </div>
    </div>
  </div>
</section>

{/*  end conclusions  */}


<div class="lightbox-target" id="research">
  <img src="/static/carpool-verification-research.png" alt="" />
  <a class="lightbox-close" href="#"></a>
</div>

<div class="lightbox-target" id="insights">
  <img src="/static/carpool-verification-insights.png" alt="" />
  <a class="lightbox-close" href="#"></a>
</div>


<div class="lightbox-target" id="prototypes">
  <img src="/static/carpool-verification-prototypes.gif" alt="" />
  <a class="lightbox-close" href="#"></a>
</div>

<div class="lightbox-target" id="testing">
  <img src="/static/carpool-verification-testing.png" alt="" />
  <a class="lightbox-close" href="#"></a>
</div>




<div class="lightbox-target" id="conclusions">
  {/* <!-- <img src="/static/carpool-verification-conclusions.png" /> --> */}
  <a class="lightbox-close" href="#"></a>
</div>
    </main>
  );
}

export default Index;
